// Fonts
@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i';
// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$fa-font-path:   "../fonts/font-awesome";
@import "node_modules/font-awesome/scss/font-awesome";

@import "node_modules/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker";

// Mixins
@mixin vertical-gradient($colorStart, $colorEnd){
  background: $colorStart; /* Old browsers */
  background: -moz-linear-gradient(top,  $colorStart 0%, $colorEnd 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  $colorStart 0%,$colorEnd 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  $colorStart 0%,$colorEnd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$colorStart', endColorstr='$colorEnd',GradientType=0 ); /* IE6-9 */
}

@mixin vertical-gradient-to-darker($colorStart,$percent:10%){
  $colorEnd: darken($colorStart,$percent);
  @include vertical-gradient($colorStart, $colorEnd);
}

//Components
@import "components/ResponsiveTable";

//Partials
@import "partials/deal-tracker";
@import "partials/splash";

//Clean
.navbar-brand {
  padding: 16px 15px 0px 15px;
  img {
    height: 54px;
  }
}

.navbar {
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  font-family: "Source Sans Pro", sans-serif;
  .navbar-nav {
    li {
      a {
        font-weight: 500;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.panel {
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  .panel-title{
    font-family: "Source Sans Pro", sans-serif;
    font-weight:500;
    font-size:130%;
  }
}

.breadcrumb {
  font-family: "Source Sans Pro", sans-serif;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
  .active{
    color: $color-primary;
    font-weight:400;
    font-size:150%;
  }
}

.FauxUserNavbar{
  min-height:40px;
  margin-bottom:0px;
  .navbar-brand{
    color:#FFF;
    height:auto;
    line-height:40px;
    margin:0px;
    padding:0px 15px;
  }
  .navbar-nav{
    > li{
      >a{
        line-height:40px;
        padding:0px 15px;
      }
    }
  }
}