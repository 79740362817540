$breakpoint-alpha: 800px; // adjust to your needs
.ResponsiveTable{

  tr {
  }

  th {
    display: none;
  }

  td {
    display: block;

    &:first-child {
    }
    &:last-child {
    }

    &:before {
      content: attr(data-th)"";
      font-weight: bold;
      display: block;
      @media (min-width: $breakpoint-alpha) {
        display: none;
      }
    }
  }

  th, td {
    text-align: left;
    @media (min-width: $breakpoint-alpha) {
      display: table-cell;
      &:first-child {
      }
      &:last-child {
      }
    }

  }
}