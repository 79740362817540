.deal-tracker-container{
  .title{
    padding-left:50px;
  }
  @media (max-width: 1000px) {
    .title{
      padding-left:0px;
    }
  }
}
.deal-tracker{
  display:flex;
  flex-direction: row;
  align-items:stretch;
  align-content:stretch;
  justify-content: space-between;
  //position: relative;
  border-radius:50px;
  //height:100px;
  @include vertical-gradient-to-darker(#1d86ad);
  margin-top:40px;
  border:solid 3px #FFF;
  box-shadow:0px 0px 5px rgba(0,0,0,0.9);
  position: relative;
  padding:0px 50px;
  .segment{
    flex: 1 100%;
    position: relative;
    min-width:120px;
    padding:0px 10px;
    text-align:center;
    border-left:solid 1px #FFF;
    border-right:solid 1px #FFF;
    margin:auto;
    height:94px;
    /*float: left;
    width:11%;

    text-align: center;
    position: relative;*/

    label{
      position: absolute;
      width:100%;
      top:-30px;
      left:0px;
      right:0px;
      color:#4c4a49;
      font-weight:bold;
      font-size:14px;
      line-height:20px;
    }
    .status{
      line-height:94px;
      color:#FFF;
      font-size:14px;
      font-weight:bold;
    }
    .message{
      position: absolute;
      width:100%;
      top:-110px;
      left:0px;
      right:0px;
      background-color:#1d86ad;
      padding:5px;
      font-size:11px;
      border:solid 3px #FFF;
      box-shadow:0px 0px 5px rgba(0,0,0,0.9);
    }
    .btn-message{
      position: absolute;
      bottom:5px;
      right:5px;
    }
    .popover{
      min-width:150px;
      font-size:12px;
    }
    .popover-title{
      margin:0px;
      background-color:#1d86ad;
      color:#FFF;
      font-size:12px;
      font-weight:bold;
    }
    &.segment-funded{
      label{
        font-size:20px;
        font-style:italic;
        text-transform: uppercase;
      }
    }
    &.segment-status-success{
      @include vertical-gradient-to-darker(#1eb065);
    }
    &.segment-status-warning{
      @include vertical-gradient-to-darker(#fdd120);
    }
    &.segment-status-danger{
      @include vertical-gradient-to-darker(#ff0000);
    }
  }
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    border-radius:0px;
    padding:0px;
    .segment{
      height:auto;
      border:none;
      border-bottom:solid 1px #FFF;
      width:100%;
      padding:5px;
      label{
        position: relative;
        height:auto;
        top: auto;
        line-height:normal;
        font-size:14px;
        color:#FFF;
        font-weight:normal;
      }
      .status{
        height:auto;
        line-height:normal;
        font-size:16px;
        color:#FFF;
        font-weight:bold;
      }
      .btn-message{
        position: relative;
        right:auto;
        bottom:auto;
      }
    }
  }
}