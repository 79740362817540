$color-primary:#0B1C39;
$color-secondary: #fbaf3c;

$border-radius-base:0px;
$border-radius-large:0px;
$border-radius-small:0px;


// Body
$body-bg: lighten($color-primary,80%);

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: $color-primary;
$brand-info: $color-secondary;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-text: $color-primary;
$panel-default-heading-bg: #fff;

$panel-bg:                    #fff !default;
$panel-body-padding:          15px !default;
$panel-heading-padding:       10px 15px !default;
$panel-footer-padding:        $panel-heading-padding !default;
$panel-border-radius:         $border-radius-base !default;

//** Border color for elements within panels
$panel-inner-border:          #ddd !default;
$panel-footer-bg:             #f5f5f5 !default;

$panel-default-text:          #666 !default;
$panel-default-border:        #ddd !default;
$panel-default-heading-bg:    #f5f5f5 !default;

$panel-primary-text:          #fff !default;
$panel-primary-border:        $brand-primary !default;
$panel-primary-heading-bg:    $brand-primary !default;


//Navbar
$navbar-height: 85px;
$navbar-default-border:0px;
$navbar-default-link-color:                $color-primary;
$navbar-default-link-hover-color:          $color-primary;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         $color-secondary;
$navbar-default-link-active-bg:            transparent;
$navbar-default-link-disabled-color:       #ccc !default;
$navbar-default-link-disabled-bg:          transparent !default;

//Breadcrumbs
$breadcrumb-padding-vertical:   15px;
$breadcrumb-padding-horizontal: 15px;
$breadcrumb-bg:                 #FFFFFF;
$breadcrumb-color:              $color-primary;
$breadcrumb-active-color:       #CCC;
$breadcrumb-separator:          "/" !default;