body.splash{
  background-color: $color-secondary;
  background-image: url('../img/splash/bg_body.png');
  background-repeat:no-repeat;
  background-position: left top;
  text-align: center;
  color: $color-primary;
  padding-top:200px;
  .welcome{
    font-size:120px;
    font-weight:300;
  }
  .welcome-logo{
    padding:100px 100px;
    img{
      margin-left:auto;
      margin-right:auto;
    }
  }
  .redirect-link{
    font-size:14px;
    a{
      color:darken($color-primary,5%);
    }
  }
}